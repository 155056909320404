<script>
export default {
	props: {
		data: Object,
	},
	data() {
		return {
			activePanels: {},
			fixedActivePanel: null,
		}
	},
	created() {
		if (this.isFixed) return
		let res = {}
		this.data.columns.forEach((_, n) => {
			res[n] = null
		})
		this.activePanels = res
	},
	computed: {
		isFixed() {
			return this.data.designType == 'fixed'
		},
	},
}
</script>

<template>
	<Container>
		<Title v-if="data.title" :title="data.title" :titleStyle="data.titleStyle" />
		<v-row v-if="!isFixed">
			<v-col cols="12" :md="12 / data.columns.length" v-for="(column, n) in data.columns" :key="n">
				<v-expansion-panels v-model="activePanels[n]">
					<v-expansion-panel
						v-for="(item, k) in column.items"
						:key="k"
						:class="item.type === 'image' ? 'rounded-xl' : ''"
						class="my-2 v-expansion-panel--next-active"
					>
						<v-expansion-panel-header
							:color="$vars.primary"
							:hide-actions="item.type !== 'text'"
							:class="{
								'pa-0': item.type == 'image',
								'rounded-b-0': item.type == 'image' && activePanels[n] == k,
							}"
							style="background-color: unset; border-color: unset"
						>
							<template v-slot:actions>
								<v-icon :color="$vars.cta"> $expand </v-icon>
							</template>
							<div
								v-if="item.type === 'text'"
								class="d-flex align-center"
								:class="item.invertTitle && 'flex-row-reverse justify-end'"
							>
								<v-icon v-if="item.titleIconType === 'icon'">{{ item.titleIcon }}</v-icon>
								<Media
									v-else-if="item.titleIconType === 'image'"
									:src="item.titleImage"
									width="50px"
								/>
								<div class="pr-3"></div>
								<span class="title basefont">{{ item.title }}</span>
							</div>
							<Media
								v-else
								:src="item.titleFullImage"
								:class="activePanels[n] == k ? 'rounded-xl rounded-b-0' : 'rounded-xl'"
								:imgClass="activePanels[n] == k ? 'rounded-xl rounded-b-0' : 'rounded-xl'"
							/>
						</v-expansion-panel-header>
						<v-expansion-panel-content
							:color="$vars.secondary"
							:class="{
								'pa-0 rounded-xl rounded-t-0': item.type == 'image',
							}"
						>
							<div class="content pa-4" v-html="item.content"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
		<div v-else>
			<v-expansion-panels v-model="fixedActivePanel" style="gap: 10px">
				<div v-for="(item, k) in data.fixedImages" :key="k">
					<v-expansion-panel class="my-2 v-expansion-panel--next-active rounded-xl">
						<v-expansion-panel-header
							:color="$vars.primary"
							hide-actions
							class="pa-0"
							:class="{
								'rounded-b-0': fixedActivePanel == k,
							}"
							style="background-color: unset; border-color: unset"
						>
							<template v-slot:actions>
								<v-icon :color="$vars.cta"> $expand </v-icon>
							</template>
							<Media
								width="380"
								height="226"
								:src="item.image"
								:class="fixedActivePanel == k ? 'rounded-xl rounded-b-0' : 'rounded-xl'"
								:imgClass="fixedActivePanel == k ? 'rounded-xl rounded-b-0' : 'rounded-xl'"
							/>
						</v-expansion-panel-header>
						<v-expansion-panel-content
							:color="$vars.secondary"
							class="pa-0 rounded-xl rounded-t-0"
							style="max-width: 380px"
						>
							<div class="content pa-4" v-html="item.content"></div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</div>
			</v-expansion-panels>
		</div>
	</Container>
</template>

<style scoped lang="scss">
.title {
	color: var(--primarytext);
}
.content {
	color: var(--secondarytext);
}

.basefont {
	font-family: var(--basefont) !important;
}

::v-deep .v-expansion-panel:not(:first-child)::after {
	border-top: none;
}
</style>
