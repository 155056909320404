import { render, staticRenderFns } from "./comp_dpc-GoogleReviews.vue?vue&type=template&id=5a5b301f&scoped=true"
import script from "./comp_dpc-GoogleReviews.vue?vue&type=script&lang=js"
export * from "./comp_dpc-GoogleReviews.vue?vue&type=script&lang=js"
import style0 from "./comp_dpc-GoogleReviews.vue?vue&type=style&index=0&id=5a5b301f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a5b301f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VIcon,VProgressCircular,VProgressLinear,VRow})
